import _ from "lodash";

export const extractCounts = (
  inventoryChartData,
  isArrivalGraphDataFetchingError,
  arrivalGraphData,
  departuresChartData,
) => {
  // today's inventory count
  const todaysInventory = inventoryChartData.data
    .filter((item) => item.isTodaysDate)
    .reduce((acc, item) => acc + item.shippable, 0);

  // calculate today's arrivals count
  let todaysArrivalsCount = 0;

  if (isArrivalGraphDataFetchingError === false) {
    // today's arrival
    if (!_.isEmpty(arrivalGraphData) && !isArrivalGraphDataFetchingError) {
      const todaysArrivals = arrivalGraphData.filter((item) => {
        return item.isTodaysDate;
      });
      if (!_.isEmpty(todaysArrivals)) {
        todaysArrivalsCount = todaysArrivals[0].reported ?? 0;
      }
    }
  }

  // count of VINs at this location based on IN milestones >= 10 days ago
  // 5% of capacity
  let fivePercentOfTotalCapacity = 0;
  if (_.has(departuresChartData, "capacity") && departuresChartData.capacity) {
    fivePercentOfTotalCapacity = Math.trunc(
      (departuresChartData.capacity * 5) / 100,
    );
  }
  return { todaysInventory, todaysArrivalsCount, fivePercentOfTotalCapacity };
};
