/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader";
import { faChartLine } from "@fortawesome/pro-regular-svg-icons";
import Colors from "styles/colors";

import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { FontSize, Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { LocalizedNumber } from "components/atoms/LocalizedNumber";
import {
  useInventoryGraph,
  useDepartureGraph,
  useArrivalGraph,
} from "pages/inventoryview/react-queries/InventoryViewHooks";
import moment from "moment";
import { getColors } from "pages/inventoryview/utils/chart.utils";
import { extractCounts } from "pages/inventoryview/utils/count.utils";
import { Alert } from "components/atoms/Alert.atom";

const metricsDivCss = {
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "1em",
  gridAutoColumns: "minmax(max-content, auto)",
  marginBottom: "1em",
  "> div": {
    padding: "10px 16px",
    borderRadius: "4px",
    backgroundColor: Colors.inventoryView.LIGHTER_GRAY,
  },
};

export const InventoryLocationPanel = ({
  location,
  redirectToInsightsView,
}) => {
  const { t } = useTranslation("inventory-view");

  // set UTC date
  const todaysDate = moment().utc().format("YYYY-MM-DD");
  const arrivalDateInUTC = moment(todaysDate)
    .subtract(7, "days")
    .format("YYYY-MM-DD");
  const forecastedEndDateInUTC = moment(todaysDate)
    .add(7, "days")
    .format("YYYY-MM-DD");

  const locationId = location.locationId;

  let params = {
    locationId: locationId,
    startDate: moment(todaysDate)
      .utc()
      .subtract(6, "days")
      .format("YYYY-MM-DD"), //today minus 6 days
    endDate: todaysDate, //today's date
  };
  const {
    transformedData: inventoryChartData,
    isFetching: isInventoryChartDataFetching,
    isError: isInventoryChartDataFetchingError,
  } = useInventoryGraph(params);

  const {
    transformedData: departuresChartData,
    isFetching: isDeparturesChartDataFetching,
    isError: isDeparturesChartDataFetchingError,
  } = useDepartureGraph(params);

  let paramsForArrivalGraph = {
    locationId,
    arrivalDateInUTC,
    todaysDateInUTC: todaysDate,
    forecastedEndDateInUTC,
  };
  const {
    transformedData: arrivalGraphData,
    isFetching: isArrivalGraphDataFetching,
    isError: isArrivalGraphDataFetchingError,
  } = useArrivalGraph(paramsForArrivalGraph);

  // Retrieve count from requested date
  const getDeparturesCount = useCallback(
    (departuresChartData, requestedDate) => {
      const yesterdaysActualDepartures = departuresChartData.data.filter(
        (data) => data.x === requestedDate,
      );

      return yesterdaysActualDepartures[0].y ?? 0;
    },
    [],
  );

  // Retrieve count from previous day
  const actualDeparturesYesterday = useMemo(() => {
    const yesterdaysDate = moment(todaysDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    if (departuresChartData.data.length !== 0) {
      return getDeparturesCount(departuresChartData, yesterdaysDate);
    }
  }, [getDeparturesCount, departuresChartData, todaysDate]);

  const isError =
    isArrivalGraphDataFetchingError &&
    isInventoryChartDataFetchingError &&
    isDeparturesChartDataFetchingError;

  // extract counts from data
  const { todaysInventory, todaysArrivalsCount, fivePercentOfTotalCapacity } =
    extractCounts(
      inventoryChartData,
      isArrivalGraphDataFetchingError,
      arrivalGraphData,
      departuresChartData,
    );
  // fivePercentOfTotalCapacity will be set once timeonsite API is integrated

  return (
    <Fragment>
      {
        <div>
          <PanelGroup>
            <PanelGroup.Header
              title={`(${location.code}) ${location.name}`}
              titleSize={FontSize.size16}
            />
            <PanelGroup.Content
              style={{
                minHeight: 150,
              }}
            >
              <Loader
                loaded={
                  !isArrivalGraphDataFetching &&
                  !isInventoryChartDataFetching &&
                  !isDeparturesChartDataFetching
                }
              >
                <div css={{ ...metricsDivCss }}>
                  <div>
                    <Text
                      color={getColors(
                        "inventory",
                        todaysInventory,
                        departuresChartData.capacity,
                      )}
                      size={FontSize.size18}
                      bold="true"
                      as="div"
                    >
                      <LocalizedNumber value={todaysInventory} />/
                      <LocalizedNumber value={departuresChartData.capacity} />
                    </Text>
                    <Text
                      size={FontSize.size12}
                      as="div"
                      color={Colors.text.DARK_GRAY}
                    >
                      {t("inventory-view:Current Inventory")}
                    </Text>
                  </div>
                  <div>
                    <Text
                      // color={getColors(
                      //   "daysOnSite",
                      //   actualDeparturesYesterday,
                      //   fivePercentOfTotalCapacity,
                      // )}
                      size={FontSize.size18}
                      bold="true"
                      as="div"
                    >
                      {/* <LocalizedNumber value={Not} /> */}
                      API not integrated yet
                    </Text>
                    <Text
                      size={FontSize.size12}
                      as="div"
                      color={Colors.text.DARK_GRAY}
                    >
                      {t("inventory-view:10+ Days on Site")}
                    </Text>
                  </div>
                </div>
                <div css={{ ...metricsDivCss }}>
                  <div>
                    <Text
                      color={getColors(
                        "forecastedArrivals",
                        todaysArrivalsCount,
                      )}
                      size={FontSize.size18}
                      bold="true"
                      as="div"
                    >
                      <LocalizedNumber value={todaysArrivalsCount} />
                    </Text>
                    <Text
                      size={FontSize.size12}
                      as="div"
                      color={Colors.text.DARK_GRAY}
                    >
                      {t("inventory-view:Today's Forecasted Arrivals")}
                    </Text>
                  </div>
                  <div>
                    <Text
                      color={getColors(
                        "departures",
                        actualDeparturesYesterday,
                        departuresChartData.primaryDepartureTarget,
                      )}
                      size={FontSize.size18}
                      bold="true"
                      as="div"
                    >
                      <LocalizedNumber value={actualDeparturesYesterday} />/
                      <LocalizedNumber
                        value={departuresChartData.primaryDepartureTarget}
                      />
                    </Text>
                    <Text
                      size={FontSize.size12}
                      as="div"
                      color={Colors.text.DARK_GRAY}
                    >
                      {t("inventory-view:Yesterday's Departures")}
                    </Text>
                  </div>
                </div>
                <div css={{ textAlign: "center" }}>
                  <Button
                    variant="primary"
                    onClick={() => redirectToInsightsView(location?.locationId)}
                  >
                    {t("inventory-view:Expand Metrics")}{" "}
                    <Icon src={faChartLine}></Icon>
                  </Button>
                </div>
              </Loader>
              <Alert show={isError} variant="danger">
                {t(
                  "inventory-view:Unable to fetch details. Please try again later.",
                )}
              </Alert>
            </PanelGroup.Content>
          </PanelGroup>
        </div>
      }
    </Fragment>
  );
};

InventoryLocationPanel.propTypes = {
  location: PropTypes.object,
  redirectToInsightsView: PropTypes.func,
};
