import _ from "lodash";
import moment from "moment";

import {
  InitialETADuration,
  InventoryStatus,
  TimeOnSiteDuration,
} from "api/consts";

import { useEntityTranslation } from "shared/hooks/useEntityTranslation";
import { useInventoryViewTranslation } from "shared/hooks/useInventoryViewTranslation";

import {
  getBasicQueryString,
  getBasicWithStaticOptionsQueryString,
  getDateRangeQueryString,
  getEverythingQueryString,
  getMultiSelectQueryString,
  getNQueryStringFilterValuePriority,
} from "components/search-bar/search-filter-query-strings";

import {
  AsyncSelectFilterButton,
  DateRangeFilterButton,
  NFilterButton,
  SelectFilterButton,
} from "components/search-bar/FilterButtons";

import {
  currentLocationTypeOptionsState,
  destinationOptionsState,
  orderTypesOptionsState,
  productTypeOptionsState,
  carrierOptionsState,
} from "pages/inventoryview/details/search/InventoryView.Details.SearchFilterLoaders";

import { isDateRangeValueValid } from "components/search-bar/search-filter-validators";
import { OrganizationType } from "shared/constants/organization.const";

export const QUERY_KEY_MAPPING = {
  ARRIVAL_DATE: "arrivalTs",
  CARRIER: "carrier",
  CURRENT_LOCATION: "atLocation",
  DEPARTURE_DATE: "departureTs",
  PRODUCT_TYPE: "productType",
  DESTINATION: "ultimateDestinationLocation",
  ENTITY_ID: "entityId",
  FORECASTED_ARRIVAL: "forecastedArrivalTs",
  INITIAL_ETA: "initialForecastedUltimateDestinationArrival",
  ORDER_TYPE: "orderType",
  PRODUCTION_DATE: "estimatedProductionTs",
  TIME_ON_SITE: "timeOnSite",
  SHIPPABILITY: "isShippable",
};

const timeOnSiteOptions = {
  [TimeOnSiteDuration.DAYS_10]: { from: 10, to: 0 },
  [TimeOnSiteDuration.DAYS_6_9]: { from: 9, to: 6 },
  [TimeOnSiteDuration.DAYS_3_5]: { from: 5, to: 3 },
  [TimeOnSiteDuration.DAYS_0_2]: { from: 2, to: 0 },
};

const initialETAOptions = {
  [InitialETADuration.MISSED_FIRST_ETA]: { start: 0, end: 0 },
  [InitialETADuration.HOURS_0_47_OUT]: { start: 0, end: 47 },
  [InitialETADuration.HOURS_48_95_OUT]: { start: 48, end: 95 },
  [InitialETADuration.HOURS_96_OUT]: { start: 0, end: 96 },
};

export const INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES = [
  {
    queryKey: QUERY_KEY_MAPPING.ENTITY_ID,
    label: () => null,
    placeholder: (t) => t("inventory-view:Search VIN"),
    queryBuilder: getEverythingQueryString,
    property: null,
  },
];

export const INVENTORY_VIEW_DETAILS_FILTERS = [
  {
    queryKey: QUERY_KEY_MAPPING.PRODUCT_TYPE,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Product Type"),
    optionsState: productTypeOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: QUERY_KEY_MAPPING.SHIPPABILITY,
    label: (t) => t("inventory-view:Shippability"),
    Component: (props) => {
      const { getTranslatedStatus } = useInventoryViewTranslation();

      const translatedOptions = props?.options?.map((option) => ({
        ...option,
        label: getTranslatedStatus(option.label),
      }));

      return <SelectFilterButton {...props} options={translatedOptions} />;
    },
    optionsGetter: () => {
      return [
        {
          label: InventoryStatus.SHIPPABLE,
          value: "true",
        },
        {
          label: InventoryStatus.NONSHIPPABLE,
          value: "false",
        },
      ];
    },
    queryBuilder: getBasicQueryString,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: QUERY_KEY_MAPPING.FORECASTED_ARRIVAL,
    label: (t) => t("inventory-view:Forecasted Arrival"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    isValueValid: isDateRangeValueValid,
    queryBuilder: (queryParameter, filterValue) =>
      getDateRangeQueryString(queryParameter, filterValue, {
        convertToUtc: true,
      }),
  },
  // {
  //   // ordered top down from 0 - N
  //   queryKey: ["currentPositionTypes", "currentPositionCodes"],
  //   label: (t) => t("inventory-view:Current Location"),
  //   Component: (props) => {
  //     const { getTranslatedCurrentLocationTypes } = useEntityTranslation();

  //     const translatedOptions = {
  //       currentPositionTypes: props?.options?.currentPositionTypes.map(
  //         (option) => ({
  //           ...option,
  //           label: getTranslatedCurrentLocationTypes(option.label),
  //         }),
  //       ),
  //     };

  //     return (
  //       <NFilterButton
  //         {...props}
  //         options={
  //           !_.isEmpty(translatedOptions.currentPositionTypes)
  //             ? translatedOptions
  //             : props.options
  //         }
  //       />
  //     );
  //   },
  //   nIsAsync: {
  //     currentPositionTypes: false,
  //     currentPositionCodes: true,
  //   },
  //   nOptionsState: {
  //     currentPositionTypes: null,
  //     currentPositionCodes: currentLocationTypeOptionsState,
  //   },
  //   //Label of filter where key is corresponding select
  //   nLabels: {
  //     currentPositionTypes: null,
  //     currentPositionCodes: "At Location",
  //   },
  //   //Fields required from previous filter
  //   //Field with contraints as key
  //   nRequirments: {
  //     currentPositionCodes: ["AtLocation"],
  //   },
  //   // Hides fuzzy search options
  //   nHideFuzzySearch: {
  //     currentPositionTypes: true,
  //     currentPositionCodes: false,
  //   },
  //   // Hides select all option
  //   nHideSelectAll: {
  //     currentPositionTypes: true,
  //     currentPositionCodes: false,
  //   },
  //   // Hides select empty option
  //   nHideSelectEmpty: {
  //     currentPositionTypes: true,
  //     currentPositionCodes: true,
  //   },
  //   //Showall filters with no requirments or contraints
  //   showAll: false,
  //   //Object key as query key
  //   optionsGetter: (props) => {
  //     return {
  //       currentPositionTypes: props.currentPositionTypes ?? [],
  //     };
  //   },
  //   queryBuilder: getNQueryStringFilterValuePriority,
  // },
  {
    queryKey: QUERY_KEY_MAPPING.PRODUCTION_DATE,
    label: (t) => t("inventory-view:Production Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    isValueValid: isDateRangeValueValid,
    queryBuilder: (queryParameter, filterValue) =>
      getDateRangeQueryString(queryParameter, filterValue, {
        formatDateTypeValue: JSON.stringify,
        convertToUtc: true,
      }),
  },
  {
    queryKey: QUERY_KEY_MAPPING.TIME_ON_SITE,
    label: (t) => t("inventory-view:Time on Site"),
    Component: (props) => {
      const { getTranslatedTimeOnSiteLabels } = useInventoryViewTranslation();

      const translatedOptions = props?.options?.map((option) => ({
        value: option,
        label: getTranslatedTimeOnSiteLabels(option),
      }));

      return <SelectFilterButton {...props} options={translatedOptions} />;
    },
    optionsGetter: () => Object.keys(timeOnSiteOptions),
    queryBuilder: (queryKey, filterValue) => {
      const startEndDuration = timeOnSiteOptions[filterValue];

      const toParam = startEndDuration.to
        ? moment().subtract(startEndDuration.to, "days")
        : null;

      const fromParam = startEndDuration.from
        ? moment().subtract(startEndDuration.from, "days")
        : null;

      const dateParams = {
        to: toParam,
        from: fromParam,
      };

      return getDateRangeQueryString(queryKey, dateParams, {
        convertToUtc: true,
      });
    },
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: QUERY_KEY_MAPPING.DESTINATION,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Destination"),
    optionsState: destinationOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: QUERY_KEY_MAPPING.INITIAL_ETA,
    label: (t) => t("inventory-view:Initial ETA"),
    Component: (props) => {
      const { getTranslatedInitialETALabels } = useInventoryViewTranslation();

      const translatedOptions = props?.options?.map((option) => ({
        value: option,
        label: getTranslatedInitialETALabels(option),
      }));

      return <SelectFilterButton {...props} options={translatedOptions} />;
    },
    optionsGetter: () => Object.keys(initialETAOptions),
    queryBuilder: (queryKey, filterValue) => {
      const startEndDuration = initialETAOptions[filterValue];

      const dateParams = {
        to: moment().add(startEndDuration.start, "hours"),
        from:
          filterValue === InitialETADuration.MISSED_FIRST_ETA
            ? null
            : moment().add(startEndDuration.end, "hours"),
      };

      return getDateRangeQueryString(queryKey, dateParams, {
        convertToUtc: true,
      });
    },
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: QUERY_KEY_MAPPING.CARRIER,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Carrier"),
    optionsState: carrierOptionsState,
    queryBuilder: getMultiSelectQueryString,
    requiredOrgTypes: [OrganizationType.SHIPPER],
  },
  {
    queryKey: QUERY_KEY_MAPPING.ARRIVAL_DATE,
    label: (t) => t("inventory-view:Arrival Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    isValueValid: isDateRangeValueValid,
    queryBuilder: (queryParameter, filterValue) =>
      getDateRangeQueryString(queryParameter, filterValue, {
        convertToUtc: true,
      }),
  },
  {
    queryKey: QUERY_KEY_MAPPING.DEPARTURE_DATE,
    label: (t) => t("inventory-view:Departure Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    isValueValid: isDateRangeValueValid,
    queryBuilder: (queryParameter, filterValue) =>
      getDateRangeQueryString(queryParameter, filterValue, {
        convertToUtc: true,
      }),
  },
  {
    queryKey: QUERY_KEY_MAPPING.ORDER_TYPE,
    label: (t) => t("inventory-view:Order Type"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: orderTypesOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
];
