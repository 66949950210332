// TODO: Use configureStore from the @reduxjs/toolkit package, which replaces createStore
import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { connectRoutes } from "redux-first-router";
import { createBrowserHistory } from "history";
import qs from "qs";

import { routesMap } from "../routes";
import AuthenticationState from "../modules/auth/AuthenticationState";
import FederatedUserPreferenceModalState from "modules/auth/FederatedUserPreferenceModalState";
import CustomerSupportState from "../modules/appnav/redux/CustomerSupportState";
import EndToEndReducer from "../pages/locations/redux/EndToEndState";
import LocationsReducer from "pages/administration/location-management/redux/Locations.state";
import ShipmentsStatusState from "pages/shipments/redux/ShipmentsState";
import PublicShipmentDetailState from "pages/shipments/redux/PublicShipmentDetailState";
import ShipmentSavedSearchCardDataState from "pages/shipments/redux/ShipmentSavedSearchCardDataState";
import EditShipmentState from "modules/shipment-detail/EditShipmentState";
import InboundLiveDwellWidgetState from "pages/shipments/redux/InboundLiveDwellWidgetState";
import InboundArrivalStatusWidgetState from "pages/shipments/redux/InboundArrivalStatusWidgetState";
import DomainDataState from "modules/domain-data/DomainDataState";
import ShipmentsDomainDataState from "modules/domain-data/ShipmentsDomainDataState";
import TitleState from "shared/redux/TitleState";
import AppConfigAuthorizationState from "shared/redux/AppConfigurationState";
import {
  billOfLadingNumberCategoryLoaderState,
  trailerAndEquipmentNumberLoaderState,
  houseReferenceNumberLoaderState,
  stccCodeLoaderState,
} from "pages/shipments/redux/ShipmentsSearchCategoryStates";
import {
  originOptionsState as shipmentOriginOptionsState,
  destinationOptionsState as shipmentDestinationOptionsState,
  billOfLadingNumberOptionsState,
  trailerAndEquipmentNumberOptionsState,
  stccCodeOptionsState,
  serviceCodeOptionsState,
  proNumberOptionsState,
  routeNumberOptionsState,
  originCityOptionsState,
  destinationCityOptionsState,
  originStateOptionsState,
  destinationStateOptionsState,
  originCountryOptionsState,
  destinationCountryOptionsState,
  shipmentCreationSourceOptionsState,
  stopLocationOptionsState,
} from "pages/shipments/components/search/Shipments.SearchFilterLoaders";
import FinVehicleDomainDataState from "modules/domain-data/FinVehicleDomainData";
import InventoryViewDomainDataState from "modules/domain-data/InventoryViewDomainData";
import VinViewDomainDataState from "modules/domain-data/VinViewDomainData";
import DriveAwayDomainDataState from "modules/domain-data/DriveAwayDomainData";
import CarrierViewDomainDataState from "modules/domain-data/CarrierViewDomainData";
import LadsState from "shared/redux/Lads.state";
import UnsubscribeState from "pages/unsubscribe/redux/UnsubscribeState";
import ShipmentsSearchBarState from "pages/shipments/redux/ShipmentsSearchBarState";
import ShipmentsWatchSearchBarState from "pages/shipments/redux/ShipmentsWatchSearchBarState";
import ShipmentsSavedSearchState from "pages/shipments/redux/ShipmentsSavedSearchState";
import { ShipmentsSavedSearchSubscriptionState } from "pages/shipments/redux/ShipmentsSavedSearchSubscriptionState";
import OrganizationsReducer from "modules/organizations/OrganizationsState";
import HeaderSearchBarState from "modules/header-bar/HeaderSearchBarState";

import MapState from "modules/map/MapState";
import LocationMatchingState from "pages/administration/location-management/unresolved-location-matching/redux/UnresolvedLocationMatching.state";
import { ProfileState } from "pages/profile/redux/ProfileState";
import CreateShipmentState from "pages/shipments/redux/CreateShipmentState";

//Reports
import ReportsState from "pages/reports/redux/ReportsState";
import ReportSchedulesState from "pages/reports/redux/ReportSchedulesState";
import ReportFolderModalState from "pages/reports/redux/ReportFolderModalState";
import ReportSubFolderState from "pages/reports/redux/ReportsUnderFolderState";
import ReportsSharedSearchBarState from "pages/reports/redux/ReportsSharedState";
import ReportsCoreSearchBarState from "pages/reports/redux/ReportsCoreState";
import ReportsMineSearchBarState from "pages/reports/redux/ReportsMineState";

import FinVehicleSearchBarState from "../pages/finishedvehicle/redux/FinVehicleSearchBarState";
import {
  originOptionsState as fvOriginOptionsState,
  destinationOptionsState as fvDestinationOptionsState,
  currentPositionsCodeOptionsState as fvCurrentPositionsCodeOptionsState,
  productTypeOptionsState as fvProductTypeOptionsState,
  lastMilestoneOptionsState as fvLastMilestoneOptionsState,
  soldToOptionsState as fvSoldToOptionsState,
  finCodeOptionsState as fvFinCodeOptionsState,
  endUserFinCodeOptionsState as fvEndUserFinCodeOptionsState,
  dealerRegionOptionsState as fvDealerRegionOptionsState,
  dealerZoneOptionsState as fvDealerZoneOptionsState,
  dealerDistrictOptionsState as fvDealerDistrictOptionsState,
  partnersOptionsState as fvPartnersOptionsState,
  originCountryFinOptionsState as fvOriginCountryFinOptionsState,
  destinationCountryFinOptionsState as fvDestinationCountryFinOptionsState,
} from "pages/finishedvehicle/search/FinVehicleSearchFilterLoaders";
import FinVehicleEntitiesState from "../pages/finishedvehicle/redux/FinVehicleEntitiesState";
import FinVehicleEntityDetailsState from "pages/finishedvehicle/redux/FinVehicleEntityDetailsState";
import FinVehicleEntityHistoricalState from "pages/finishedvehicle/redux/FinVehicleEntityHistoricalState";
import FinVehicleExceptionsState from "../pages/finishedvehicle/redux/FinVehicleExceptionsState";
import FinVehicleHoldsState from "../pages/finishedvehicle/redux/FinVehicleHoldsState";
import FinVehicleSavedSearchState from "../pages/finishedvehicle/redux/FinVehicleSavedSearchState";
import FinVehicleSavedSearchCardsState from "../pages/finishedvehicle/redux/FinVehicleSavedSearchCardsState";
import FinVehicleTripLegState from "pages/finishedvehicle/redux/FinVehicleTripLegState";
import FinVehicleSubscriptionState from "pages/finishedvehicle/redux/FinVehicleSubscriptionState";
import ShipmentSubscriptionState from "../modules/shipment-detail/ShipmentSubscriptionState";
import FinVehicleGroupCategoryState from "../pages/finishedvehicle/redux/FinVehicleGroupCategoryState";
import ShipmentStopsSearchBarState from "pages/shipments/redux/ShipmentStopsSearchBarState";
import DocumentationState from "../modules/documentation/DocumentationState";
import ApiLogsSearchBarState from "../modules/documentation/api-logs/ApiLogsSearchBarState";
import MilestoneLogsSearchBarState from "modules/documentation/milestone-logs/MilestoneLogsSearchBarState";
// VinView
import VinViewEntitiesState from "pages/vinview/redux/VinViewEntitiesState";
import VinViewEntityDetailsState from "pages/vinview/redux/VinViewEntityDetailsState";
import VinViewEntityHistoricalState from "pages/vinview/redux/VinViewEntityHistoricalState";
import VinViewSavedSearchCardsState from "pages/vinview/redux/VinViewSavedSearchCardsState";
import VinViewSavedSearchState from "pages/vinview/redux/VinViewSavedSearchState";
import VinViewSearchBarState from "pages/vinview/redux/VinViewSearchBarState";
import {
  dealerOrgOptionsState,
  currentPositionsCodeOptionsState as vinViewCurrentPositionsCodeOptionsState,
  productTypeOptionsState as vinViewProductTypeOptionsState,
  vinViewLocationsOptionsState,
  vinViewLastMilestoneOptionsState,
  vinViewSoldToOptionsState,
  vinViewFinCodeOptionsState,
  vinViewEndUserFinCodeOptionsState,
  vinViewOriginOptionsState,
} from "pages/vinview/redux/VinViewSearchFilterLoaders";
import VinViewTripLegState from "pages/vinview/redux/VinViewTripLegState";
import VinViewSubscriptionState from "pages/vinview/redux/VinViewSubscriptionState";
// DriveAway
import DriveAwayEntitiesState from "pages/driveaway/redux/DriveAwayEntitiesState";
import DriveAwaySearchBarState from "pages/driveaway/redux/DriveAwaySearchBarState";
import RequestDriveAwayState from "pages/driveaway/redux/RequestDriveAway";
import DriveAwaySavedSearchState from "pages/driveaway/redux/DriveAwaySavedSearchState";
import DriveAwaySavedSearchCardsState from "pages/driveaway/redux/DriveAwaySavedSearchCardsState";
import {
  driveAwayLocationsOptionsState,
  driveAwayCustomersOptionsState,
  driveAwayDealersOptionsState,
  driveAwayProductTypeOptionsState,
  driveAwayDealerRegionOptionsState,
  driveAwayDealerZoneOptionsState,
  driveAwayDealerDistrictOptionsState,
  driveAwayDestinationOptionsState,
} from "pages/driveaway/redux/DriveAwaySearchFilterLoaders";
import DriveAwayReviewRequestState from "pages/driveaway/redux/DriveAwayReviewRequest";
import DriveAwayExportPickUpFormState from "pages/driveaway/redux/DriveAwayExportPickUpFormState";
import DriveAwayBulkActionState from "pages/driveaway/redux/DriveAwayBulkActionModalState";
import DriveAwayStatusHistoryState from "pages/driveaway/redux/DriveAwayStatusHistoryState";
import DriveAwayVinHistorySearchBarState from "pages/driveaway/redux/DriveAwayVinHistorySearchBarState";

// CarrierView
import CarrierViewEntitiesState from "pages/carrierview/redux/CarrierViewEntitiesState";
import CarrierViewEntityDetailsState from "pages/carrierview/redux/CarrierViewEntityDetailsState";
import CarrierViewEntityHistoricalState from "pages/carrierview/redux/CarrierViewEntityHistoricalState";
import CarrierViewSavedSearchCardsState from "pages/carrierview/redux/CarrierViewSavedSearchCardsState";
import CarrierViewSavedSearchState from "pages/carrierview/redux/CarrierViewSavedSearchState";
import CarrierViewSearchBarState from "pages/carrierview/redux/CarrierViewSearchBarState";
import {
  currentPositionsCodeOptionsState as carrierViewCurrentPositionsCodeOptionsState,
  productTypeOptionsState as carrierViewProductTypeOptionsState,
} from "pages/carrierview/redux/CarrierViewSearchFilterLoaders";
import CarrierViewTripLegState from "pages/carrierview/redux/CarrierViewTripLegState";
import CarrierViewSubscriptionState from "pages/carrierview/redux/CarrierViewSubscriptionState";

// Metal Tracking
import MetalTrackingSearchBarState from "../modules/mt-search/MetalTrackingSearchBarState";
import MetalTrackingSavedSearchState from "../modules/mt-search/MetalTrackingSavedSearchState";
import MetalTrackingDomainDataState from "../modules/domain-data/MetalTrackingDomainData";

// Inventory View
import InventoryViewDashboardState from "pages/inventoryview/redux/InventoryViewDashboardState";
import InventoryViewDetailsState from "pages/inventoryview/redux/InventoryViewDetailsState";
import InventoryViewDetailsSearchState from "pages/inventoryview/redux/InventoryViewDetailsSearchBarState";
import {
  productTypeOptionsState,
  destinationOptionsState,
  currentLocationTypeOptionsState,
  currentLocationOptionsState,
  orderTypesOptionsState,
  carrierOptionsState,
} from "pages/inventoryview/details/search/InventoryView.Details.SearchFilterLoaders";

// Damage View
import DamageViewDashboardState from "pages/damageview/redux/DamageViewDashboardState";
import DamageViewWatchedSubmissionsWidgetState from "pages/damageview/redux/DamageViewWatchedSubmissionState";
import DamageViewMySubmissionsWidgetState from "pages/damageview/redux/DamageViewMySubmissionsState";
import DamageViewSearchBarState from "pages/damageview/redux/DamageViewSearchBarState";
import DamageViewDetailsSearchState from "pages/damageview/redux/DamageViewDetailsSearchState";
import {
  damageViewAssigneesOptionsState,
  damageViewPartnersOptionsState,
  currentPositionsCodeOptionsState as damageViewCurrentPositionsCodeOptionsState,
  productTypeOptionsState as damageViewProductTypeOptionsState,
  damageAreaOptionsState,
  lastMilestoneOptionsState as damageViewLastMilestoneOptionsState,
  stolenVinOptionsState as damageViewStolenVinOptionsState,
} from "pages/damageview/redux/DamageViewSearchFilterLoaders";
import DamageViewDomainDataState from "pages/damageview/components/search/DamageViewDomainData";
import DamageViewDetailsState from "pages/damageview/redux/DamageViewDetailsState";
import DamageViewExportSubmissionState from "pages/damageview/redux/DamageViewExportSubmissionState";
import DamageViewDownloadPdfState from "pages/damageview/redux/DamageViewDownloadPdfState";
import DamageViewDamageFormState from "pages/damageview/redux/DamageViewDamageFormState";
import DamageViewSubmissionsByStatusState from "pages/damageview/redux/DamageViewSubmissionsByStatusState";

import ConnectedCarSearchBarState from "pages/connectedcar/redux/ConnectedCarSearchBarState";
import ConnectedCarDomainDataState from "../modules/domain-data/ConnectedCarDomainData";
import HealthcareState from "pages/healthcare/redux/HealthcareState";
import HealthcareSearchBarState from "pages/healthcare/redux/HealthcareSearchBarState";
import HealthcareDomainDataState from "../modules/domain-data/HealthcareDomainData";
import LocationSearchBarState from "pages/administration/location-management/locations/redux/Location.SearchBar.state";
import { LocationMatchingViewSearchBarState } from "pages/administration/location-management/unresolved-location-matching/redux/UnresolvedLocationMatching.SearchBar.state";
import UnresolvedLocationSearchBarState from "pages/administration/location-management/unresolved-locations/search/UnresolvedLocations.SearchBar.state";
import UsersState from "modules/users/UsersState";
import RolesState from "../modules/roles/RolesState";
import CallbackReducer from "../pages/callback/redux/CallbackState";
import MilestoneDashboardSearchBarState from "../pages/milestone/search/MilestoneDashboardSearchBarState";
import MilestoneDomainDataState from "../pages/milestone/redux/MilestoneDomainDataState";
import StatusUpdateDetailState from "../pages/milestone/redux/StatusUpdateDetailState";
import CreateMilestoneState from "../pages/createmilestone/redux/CreateMilestoneState";
import ApplicationConfig from "application-config";
import ExportsState from "../modules/exports/ExportsState";
import NotificationsState from "../modules/notifications/NotificationsState";
import { milestoneDashboardLocationsOptionsState } from "pages/milestone/search/MilestoneEventFilterLoaders";
import { connectedCarCityOptionsState } from "pages/connectedcar/redux/ConnectedCarFilterLoaders";
import OrganizationsSearchBarState from "modules/organizations/OrganizationsSearchBarState";
import { milestoneLogsLocationsOptionsState } from "modules/documentation/milestone-logs/MilestoneLogsFilterLoaders";
import UsersSearchBarState from "modules/users/UsersSearchBarState";
import ShipmentDwellNotificationSearchBarState from "pages/administration/admin-tools/shipment-dwell-notification/redux/ShipmentDwellNotificationSearchBarState";
import { shipmentDwellNotificationLocationOptionsState } from "pages/administration/admin-tools/shipment-dwell-notification/redux/ShipmentDwellNotificationSearchFilterLoaders";
import EditShipmentDwellNotificationState from "pages/administration/admin-tools/shipment-dwell-notification/redux/EditShipmentDwellNotificationState";
//Part View
import PartViewSearchBarState from "pages/partview/redux/PartViewSearchBarState";
import PartViewDashboardState from "pages/partview/redux/PartViewDashboardState";
import PartViewBackOrderDashboardState from "pages/partview/redux/PartViewBackOrderDashboardState";
import PartViewSavedSearchState from "pages/partview/redux/PartViewSavedSearchState";
import PartViewSavedSearchCardsState from "pages/partview/redux/PartViewSavedSearchCardsState";
import PartViewEntityDetailsState from "pages/partview/redux/PartViewEntityDetailsState";
import PartViewSubscriptionState from "pages/partview/redux/PartViewSubscriptionState";
import { PartViewWatchedPackagesSubscriptionState } from "pages/partview/redux/PartViewWatchedPackagesSubscriptionState";
import PartViewDeliverPackageState from "pages/partview/redux/PartViewDeliverPackageState";
import {
  partViewShipmentIdOptionsState,
  partViewPartNumberOptionsState,
  partViewOrderNumberOptionsState,
  partViewPackageNumberOptionsState,
  partViewBillOfLadingOptionsState,
} from "pages/partview/redux/PartViewSearchCategoryLoader";
import { originOptionsState as partViewOriginOptionsState } from "pages/partview/redux/PartViewSearchFilterLoaders";
import { destinationOptionsState as partViewDestinationOptionsState } from "pages/partview/redux/PartViewSearchFilterLoaders";
import { originStateOptionsState as partViewOriginStateOptionsState } from "pages/partview/redux/PartViewSearchFilterLoaders";
import { destinationStateOptionsState as partViewDestinationStateOptionsState } from "pages/partview/redux/PartViewSearchFilterLoaders";
import { carrierOptionsState as partViewCarrierOptionsState } from "pages/partview/redux/PartViewSearchFilterLoaders";
import { partNumberOptionsState as partViewPartNumberFilterOptionsState } from "pages/partview/redux/PartViewSearchFilterLoaders";
import { packageStatusOptionsState as partViewPackageStatusOptionsState } from "pages/partview/redux/PartViewSearchFilterLoaders";
import { activeExceptionOptionsState as partViewActiveExceptionOptionsState } from "pages/partview/redux/PartViewSearchFilterLoaders";
import { orderPriorityOptionsState as partViewOrderPriorityOptionsState } from "pages/partview/redux/PartViewSearchFilterLoaders";
//Dealer_PartView
import DealerPartViewSearchBarState from "pages/partview/redux/DealerPartViewSearchBarState";
import DealerPartViewSavedSearchState from "pages/partview/redux/DealerPartViewSavedSearchState";
import DealerPartViewSavedSearchCardsState from "pages/partview/redux/DealerPartViewSavedSearchCardsState";
import { dealerOriginStateOptionsState } from "pages/partview/redux/DealerPartViewSearchFilterLoaders";
import { dealerDestinationStateOptionsState } from "pages/partview/redux/DealerPartViewSearchFilterLoaders";
import { dealerPartNumberOptionsState } from "pages/partview/redux/DealerPartViewSearchFilterLoaders";
import { dealerPackageStatusOptionsState } from "pages/partview/redux/DealerPartViewSearchFilterLoaders";
import { dealerActiveExceptionOptionsState } from "pages/partview/redux/DealerPartViewSearchFilterLoaders";
import { dealerOrderPriorityOptionsState } from "pages/partview/redux/DealerPartViewSearchFilterLoaders";
import { dealerPVOrgOptionsState } from "pages/partview/redux/DealerPartViewSearchFilterLoaders";

//Container Tracking
import ContainerTrackingSavedSearchState from "pages/containertracking/redux/ContainerTrackingSavedSearchState";
import ContainerTrackingSavedSearchCardsState from "pages/containertracking/redux/ContainerTrackingSavedSearchCardsState";
import ContainerTrackingSearchBarState from "pages/containertracking/redux/ContainerTrackingSearchBarState";
import ContainerTrackingRouteManagementSearchState from "pages/containertracking/redux/RouteManagementSearchBarState";
import ContainerTrackingManagementSearchState from "pages/containertracking/redux/ContainerManagementSearchBarState";
import { ContainerTrackingDomainData as ContainerTrackingDomainDataState } from "pages/containertracking/modules/domain-data/ContainerTrackingDomainData";
import { ContainerTrackingCountsWidgetState } from "pages/containertracking/redux/ContainerTrackingCountsWidgetState";
import { ContainerTrackingDetailsWidgetState } from "pages/containertracking/redux/ContainerTrackingDetailsWidgetState";
import { ContainerTrackingLocationManagementState } from "pages/containertracking/redux/ContainerTrackingLocationManagementState";
import {
  locationOptionsState as ctLocationOptionsState,
  supplierCodeFilterOptions as ctSupplierCodeFilterOptions,
  programCodeFilterOptions as ctProgramCodeFilterOptions,
  containerTypeFilterOptions as ctContainerTypeFilterOptions,
  locationOnRouteFilterOptions as ctLocationOnRouteFilterOptions,
  sensorFilterOptions as ctSensorFilterOptions,
} from "pages/containertracking/modules/domain-data/ContainerTrackingSearchFilterLoaders";
import { locationOptionsState as hsttLocationOptionsState } from "pages/surgicaltotetracking/modules/domain-data/SurgicalToteTrackingFilterLoaders";
import ContainerTrackingMyWatchedContainersState from "pages/containertracking/redux/ContainerTrackingMyWatchedContainersState";
import ContainerTrackingContainerTypeTableState from "pages/containertracking/redux/ContainerTrackingContainerTypeTableState";
// Surgical Tote Tracking
import { uniqueDeviceIdOptionsState as surgicalTrackingUniqueDeviceId } from "pages/surgicaltotetracking/modules/domain-data/SurgicalToteTrackingFilterLoaders";
import SurgicalToteTrackingSavedSearchState from "pages/surgicaltotetracking/redux/SurgicalToteTrackingSavedSearchState";
import SurgicalToteTrackingSavedSearchCardsState from "pages/surgicaltotetracking/redux/SurgicalToteTrackingSavedSearchCardsState";
import SurgicalToteTrackingSearchBarState from "pages/surgicaltotetracking/redux/SurgicalToteTrackingSearchBarState";
import SurgicalToteTrackingDomainDataState from "pages/surgicaltotetracking/modules/domain-data/SurgicalToteTrackingDomainData";
import { SurgicalToteTrackingCountsWidgetState } from "pages/surgicaltotetracking/redux/SurgicalToteTrackingCountsWidgetState";
import SurgicalToteTrackingMyWatchedTotesState from "pages/surgicaltotetracking/redux/SurgicalToteTrackingMyWatchedTotesState";
import { SurgicalToteTrackingDetailsWidgetState } from "pages/surgicaltotetracking/redux/SurgicalToteTrackingDetailsWidgetState";

//Critical Parts
import CriticalPartsState from "pages/critical-parts/redux/CriticalParts.state";

// Internal Tools
import VinEtaValidatorSearchBarState from "pages/administration/internal-tools/redux/VinEtaValidatorSearchBarState";
import { vinEtaValidatorVinOptionsState } from "pages/administration/internal-tools/redux/VinEtaValidatorFilterLoaders";
import ShipmentEtaValidatorSearchBarState from "pages/administration/internal-tools/redux/ShipmentEtaValidatorSearchBarState";
import FreezeETAState from "pages/administration/internal-tools/redux/FreezeETAState";
import DPUAdminToolState from "pages/administration/internal-tools/redux/DPUAdminToolState";
import DebugEtaToolState from "pages/administration/internal-tools/redux/DebugETAToolState";
import FreezePartViewETAState from "pages/administration/internal-tools/redux/FreezePartViewETAToolState";
import SetManualETARangeToolState from "pages/administration/internal-tools/redux/SetManualETARangeToolState";
import { PartViewDealerOnboardingToolState } from "pages/administration/internal-tools/redux/PartViewDealerOnboardingToolState";
import { PartViewDeliveryStatusUpdateState } from "pages/administration/internal-tools/redux/PartViewDeliveryStatusUpdateState";
import DPUVinHistorySearchBarState from "pages/administration/internal-tools/redux/DPUVinHistorySearchBarState";

// Notification Management
import NotificationManagementState from "pages/administration/notification-management/redux/NotificationManagementState";

// Administrative Tools
import ShipmentReportArrivalDepartureState from "pages/administration/redux/ShipmentReportArrivalDepartureState";
import NotificationManagementWizardState from "pages/administration/notification-management/redux/NotificationManagementWizardState";

// where redux-first-router keeps the routing state
export const ROUTER_LOCATION_KEY = "location";

const appReducers = {
  [AuthenticationState.mountPoint]: AuthenticationState.reducer,
  [FederatedUserPreferenceModalState.mountPoint]:
    FederatedUserPreferenceModalState.reducer,
  [CustomerSupportState.mountPoint]: CustomerSupportState.reducer,
  callback: CallbackReducer,
  endToEnd: EndToEndReducer,
  locations: LocationsReducer,
  organizations: OrganizationsReducer,
  [HeaderSearchBarState.mountPoint]: HeaderSearchBarState.reducer,
  [UsersState.mountPoint]: UsersState.reducer,
  [ExportsState.mountPoint]: ExportsState.reducer,
  [NotificationsState.mountPoint]: NotificationsState.reducer,
  [ProfileState.mountPoint]: ProfileState.reducer,
  [CreateShipmentState.mountPoint]: CreateShipmentState.reducer,
  [RolesState.mountPoint]: RolesState.reducer,
  [MapState.mountPoint]: MapState.reducer,
  [CriticalPartsState.mountPoint]: CriticalPartsState.reducer,
  [LadsState.mountPoint]: LadsState.reducer,
  [UnsubscribeState.mountPoint]: UnsubscribeState.reducer,
  [TitleState.mountPoint]: TitleState.reducer,
  [AppConfigAuthorizationState.mountPoint]: AppConfigAuthorizationState.reducer,

  // Domain Data
  [DomainDataState.mountPoint]: DomainDataState.reducer,

  // Locations
  [LocationMatchingState.mountPoint]: LocationMatchingState.reducer,
  [LocationSearchBarState.mountPoint]: LocationSearchBarState.reducer,
  [LocationMatchingViewSearchBarState.mountPoint]:
    LocationMatchingViewSearchBarState.reducer,
  [UnresolvedLocationSearchBarState.mountPoint]:
    UnresolvedLocationSearchBarState.reducer,

  // Shipments
  [ShipmentsDomainDataState.mountPoint]: ShipmentsDomainDataState.reducer,
  [ShipmentsStatusState.mountPoint]: ShipmentsStatusState.reducer,
  [PublicShipmentDetailState.mountPoint]: PublicShipmentDetailState.reducer,
  [ShipmentSavedSearchCardDataState.mountPoint]:
    ShipmentSavedSearchCardDataState.reducer,
  [EditShipmentState.mountPoint]: EditShipmentState.reducer,
  [InboundArrivalStatusWidgetState.mountPoint]:
    InboundArrivalStatusWidgetState.reducer,
  [InboundLiveDwellWidgetState.mountPoint]: InboundLiveDwellWidgetState.reducer,
  [ShipmentsSearchBarState.mountPoint]: ShipmentsSearchBarState.reducer,
  [ShipmentsWatchSearchBarState.mountPoint]:
    ShipmentsWatchSearchBarState.reducer,
  [ShipmentsSavedSearchState.mountPoint]: ShipmentsSavedSearchState.reducer,
  [ShipmentStopsSearchBarState.mountPoint]: ShipmentStopsSearchBarState.reducer,
  [ShipmentSubscriptionState.mountPoint]: ShipmentSubscriptionState.reducer,
  [ShipmentsSavedSearchSubscriptionState.mountPoint]:
    ShipmentsSavedSearchSubscriptionState.reducer,
  // category loaders
  [billOfLadingNumberCategoryLoaderState.mountPoint]:
    billOfLadingNumberCategoryLoaderState.reducer,
  [trailerAndEquipmentNumberLoaderState.mountPoint]:
    trailerAndEquipmentNumberLoaderState.reducer,
  [houseReferenceNumberLoaderState.mountPoint]:
    houseReferenceNumberLoaderState.reducer,
  [stccCodeLoaderState.mountPoint]: stccCodeLoaderState.reducer,
  // filter loaders
  [shipmentOriginOptionsState.mountPoint]: shipmentOriginOptionsState.reducer,
  [shipmentDestinationOptionsState.mountPoint]:
    shipmentDestinationOptionsState.reducer,
  [billOfLadingNumberOptionsState.mountPoint]:
    billOfLadingNumberOptionsState.reducer,
  [trailerAndEquipmentNumberOptionsState.mountPoint]:
    trailerAndEquipmentNumberOptionsState.reducer,
  [stccCodeOptionsState.mountPoint]: stccCodeOptionsState.reducer,
  [serviceCodeOptionsState.mountPoint]: serviceCodeOptionsState.reducer,
  [proNumberOptionsState.mountPoint]: proNumberOptionsState.reducer,
  [routeNumberOptionsState.mountPoint]: routeNumberOptionsState.reducer,
  [originCityOptionsState.mountPoint]: originCityOptionsState.reducer,
  [destinationCityOptionsState.mountPoint]: destinationCityOptionsState.reducer,
  [originStateOptionsState.mountPoint]: originStateOptionsState.reducer,
  [destinationStateOptionsState.mountPoint]:
    destinationStateOptionsState.reducer,
  [originCountryOptionsState.mountPoint]: originCountryOptionsState.reducer,
  [destinationCountryOptionsState.mountPoint]:
    destinationCountryOptionsState.reducer,
  [shipmentCreationSourceOptionsState.mountPoint]:
    shipmentCreationSourceOptionsState.reducer,
  [stopLocationOptionsState.mountPoint]: stopLocationOptionsState.reducer,

  // Finished Vehicle
  [FinVehicleDomainDataState.mountPoint]: FinVehicleDomainDataState.reducer,
  [FinVehicleSearchBarState.mountPoint]: FinVehicleSearchBarState.reducer,
  [FinVehicleEntitiesState.mountPoint]: FinVehicleEntitiesState.reducer,
  [FinVehicleEntityDetailsState.mountPoint]:
    FinVehicleEntityDetailsState.reducer,
  [FinVehicleEntityHistoricalState.mountPoint]:
    FinVehicleEntityHistoricalState.reducer,
  [FinVehicleExceptionsState.mountPoint]: FinVehicleExceptionsState.reducer,
  [FinVehicleHoldsState.mountPoint]: FinVehicleHoldsState.reducer,
  [FinVehicleSavedSearchState.mountPoint]: FinVehicleSavedSearchState.reducer,
  [FinVehicleSavedSearchCardsState.mountPoint]:
    FinVehicleSavedSearchCardsState.reducer,
  [FinVehicleTripLegState.mountPoint]: FinVehicleTripLegState.reducer,
  [FinVehicleSubscriptionState.mountPoint]: FinVehicleSubscriptionState.reducer,
  [FinVehicleGroupCategoryState.mountPoint]:
    FinVehicleGroupCategoryState.reducer,
  // filter loaders
  [fvOriginOptionsState.mountPoint]: fvOriginOptionsState.reducer,
  [fvDestinationOptionsState.mountPoint]: fvDestinationOptionsState.reducer,
  [fvCurrentPositionsCodeOptionsState.mountPoint]:
    fvCurrentPositionsCodeOptionsState.reducer,
  [fvProductTypeOptionsState.mountPoint]: fvProductTypeOptionsState.reducer,
  [fvLastMilestoneOptionsState.mountPoint]: fvLastMilestoneOptionsState.reducer,
  [fvSoldToOptionsState.mountPoint]: fvSoldToOptionsState.reducer,
  [fvFinCodeOptionsState.mountPoint]: fvFinCodeOptionsState.reducer,
  [fvEndUserFinCodeOptionsState.mountPoint]:
    fvEndUserFinCodeOptionsState.reducer,
  [fvDealerRegionOptionsState.mountPoint]: fvDealerRegionOptionsState.reducer,
  [fvDealerZoneOptionsState.mountPoint]: fvDealerZoneOptionsState.reducer,
  [fvDealerDistrictOptionsState.mountPoint]:
    fvDealerDistrictOptionsState.reducer,
  [fvPartnersOptionsState.mountPoint]: fvPartnersOptionsState.reducer,
  [fvOriginCountryFinOptionsState.mountPoint]:
    fvOriginCountryFinOptionsState.reducer,
  [fvDestinationCountryFinOptionsState.mountPoint]:
    fvDestinationCountryFinOptionsState.reducer,

  // Reports
  [ReportsState.mountPoint]: ReportsState.reducer,
  [ReportSchedulesState.mountPoint]: ReportSchedulesState.reducer,
  [ReportFolderModalState.mountPoint]: ReportFolderModalState.reducer,
  [ReportSubFolderState.mountPoint]: ReportSubFolderState.reducer,
  [ReportsSharedSearchBarState.mountPoint]: ReportsSharedSearchBarState.reducer,
  [ReportsCoreSearchBarState.mountPoint]: ReportsCoreSearchBarState.reducer,
  [ReportsMineSearchBarState.mountPoint]: ReportsMineSearchBarState.reducer,

  // Metal Tracking
  [MetalTrackingDomainDataState.mountPoint]:
    MetalTrackingDomainDataState.reducer,
  [MetalTrackingSearchBarState.mountPoint]: MetalTrackingSearchBarState.reducer,
  [MetalTrackingSavedSearchState.mountPoint]:
    MetalTrackingSavedSearchState.reducer,

  // Inventory View
  [InventoryViewDashboardState.mountPoint]: InventoryViewDashboardState.reducer,
  [InventoryViewDetailsState.mountPoint]: InventoryViewDetailsState.reducer,
  [InventoryViewDetailsSearchState.mountPoint]:
    InventoryViewDetailsSearchState.reducer,
  [productTypeOptionsState.mountPoint]: productTypeOptionsState.reducer,
  [destinationOptionsState.mountPoint]: destinationOptionsState.reducer,
  [currentLocationTypeOptionsState.mountPoint]:
    currentLocationTypeOptionsState.reducer,
  [currentLocationOptionsState.mountPoint]: currentLocationOptionsState.reducer,
  [orderTypesOptionsState.mountPoint]: orderTypesOptionsState.reducer,
  [carrierOptionsState.mountPoint]: carrierOptionsState.reducer,
  [InventoryViewDomainDataState.mountPoint]:
    InventoryViewDomainDataState.reducer,
  // Damage View
  [DamageViewDashboardState.mountPoint]: DamageViewDashboardState.reducer,
  [DamageViewWatchedSubmissionsWidgetState.mountPoint]:
    DamageViewWatchedSubmissionsWidgetState.reducer,
  [DamageViewMySubmissionsWidgetState.mountPoint]:
    DamageViewMySubmissionsWidgetState.reducer,
  [DamageViewSearchBarState.mountPoint]: DamageViewSearchBarState.reducer,
  [DamageViewDetailsSearchState.mountPoint]:
    DamageViewDetailsSearchState.reducer,
  [damageViewAssigneesOptionsState.mountPoint]:
    damageViewAssigneesOptionsState.reducer,
  [damageViewPartnersOptionsState.mountPoint]:
    damageViewPartnersOptionsState.reducer,
  [damageViewCurrentPositionsCodeOptionsState.mountPoint]:
    damageViewCurrentPositionsCodeOptionsState.reducer,
  [damageViewProductTypeOptionsState.mountPoint]:
    damageViewProductTypeOptionsState.reducer,
  [damageViewLastMilestoneOptionsState.mountPoint]:
    damageViewLastMilestoneOptionsState.reducer,
  [damageViewStolenVinOptionsState.mountPoint]:
    damageViewStolenVinOptionsState.reducer,
  [damageAreaOptionsState.mountPoint]: damageAreaOptionsState.reducer,
  [DamageViewDomainDataState.mountPoint]: DamageViewDomainDataState.reducer,
  [DamageViewDetailsState.mountPoint]: DamageViewDetailsState.reducer,
  [DamageViewExportSubmissionState.mountPoint]:
    DamageViewExportSubmissionState.reducer,
  [DamageViewDownloadPdfState.mountPoint]: DamageViewDownloadPdfState.reducer,
  [DamageViewDamageFormState.mountPoint]: DamageViewDamageFormState.reducer,
  [DamageViewSubmissionsByStatusState.mountPoint]:
    DamageViewSubmissionsByStatusState.reducer,

  // Connected Car
  [ConnectedCarDomainDataState.mountPoint]: ConnectedCarDomainDataState.reducer,
  [ConnectedCarSearchBarState.mountPoint]: ConnectedCarSearchBarState.reducer,
  // filter loaders
  [connectedCarCityOptionsState.mountPoint]:
    connectedCarCityOptionsState.reducer,

  // Healthcare
  [HealthcareState.mountPoint]: HealthcareState.reducer,
  [HealthcareSearchBarState.mountPoint]: HealthcareSearchBarState.reducer,
  [HealthcareDomainDataState.mountPoint]: HealthcareDomainDataState.reducer,

  // Documentation
  [DocumentationState.mountPoint]: DocumentationState.reducer,
  [ApiLogsSearchBarState.mountPoint]: ApiLogsSearchBarState.reducer,
  [MilestoneLogsSearchBarState.mountPoint]: MilestoneLogsSearchBarState.reducer,
  [milestoneLogsLocationsOptionsState.mountPoint]:
    milestoneLogsLocationsOptionsState.reducer,

  // Milestones
  [MilestoneDomainDataState.mountPoint]: MilestoneDomainDataState.reducer,
  [MilestoneDashboardSearchBarState.mountPoint]:
    MilestoneDashboardSearchBarState.reducer,
  [StatusUpdateDetailState.mountPoint]: StatusUpdateDetailState.reducer,
  [CreateMilestoneState.mountPoint]: CreateMilestoneState.reducer,
  // filter loaders
  [milestoneDashboardLocationsOptionsState.mountPoint]:
    milestoneDashboardLocationsOptionsState.reducer,

  // VinView
  [VinViewDomainDataState.mountPoint]: VinViewDomainDataState.reducer,
  [VinViewEntitiesState.mountPoint]: VinViewEntitiesState.reducer,
  [VinViewEntityDetailsState.mountPoint]: VinViewEntityDetailsState.reducer,
  [VinViewEntityHistoricalState.mountPoint]:
    VinViewEntityHistoricalState.reducer,
  [VinViewSavedSearchCardsState.mountPoint]:
    VinViewSavedSearchCardsState.reducer,
  [VinViewSavedSearchState.mountPoint]: VinViewSavedSearchState.reducer,
  [VinViewSearchBarState.mountPoint]: VinViewSearchBarState.reducer,
  [VinViewTripLegState.mountPoint]: VinViewTripLegState.reducer,
  [VinViewSubscriptionState.mountPoint]: VinViewSubscriptionState.reducer,
  // filter loaders
  [dealerOrgOptionsState.mountPoint]: dealerOrgOptionsState.reducer,
  [vinViewCurrentPositionsCodeOptionsState.mountPoint]:
    vinViewCurrentPositionsCodeOptionsState.reducer,
  [vinViewLastMilestoneOptionsState.mountPoint]:
    vinViewLastMilestoneOptionsState.reducer,
  [vinViewProductTypeOptionsState.mountPoint]:
    vinViewProductTypeOptionsState.reducer,
  [vinViewLocationsOptionsState.mountPoint]:
    vinViewLocationsOptionsState.reducer,
  [vinViewSoldToOptionsState.mountPoint]: vinViewSoldToOptionsState.reducer,
  [vinViewFinCodeOptionsState.mountPoint]: vinViewFinCodeOptionsState.reducer,
  [vinViewEndUserFinCodeOptionsState.mountPoint]:
    vinViewEndUserFinCodeOptionsState.reducer,
  [vinViewOriginOptionsState.mountPoint]: vinViewOriginOptionsState.reducer,

  // CarrierView
  [CarrierViewDomainDataState.mountPoint]: CarrierViewDomainDataState.reducer,
  [CarrierViewEntitiesState.mountPoint]: CarrierViewEntitiesState.reducer,
  [CarrierViewEntityDetailsState.mountPoint]:
    CarrierViewEntityDetailsState.reducer,
  [CarrierViewEntityHistoricalState.mountPoint]:
    CarrierViewEntityHistoricalState.reducer,
  [CarrierViewSavedSearchCardsState.mountPoint]:
    CarrierViewSavedSearchCardsState.reducer,
  [CarrierViewSavedSearchState.mountPoint]: CarrierViewSavedSearchState.reducer,
  [CarrierViewSearchBarState.mountPoint]: CarrierViewSearchBarState.reducer,
  [CarrierViewTripLegState.mountPoint]: CarrierViewTripLegState.reducer,
  [CarrierViewSubscriptionState.mountPoint]:
    CarrierViewSubscriptionState.reducer,
  // filter loaders
  [carrierViewCurrentPositionsCodeOptionsState.mountPoint]:
    carrierViewCurrentPositionsCodeOptionsState.reducer,
  [carrierViewProductTypeOptionsState.mountPoint]:
    carrierViewProductTypeOptionsState.reducer,

  // Organizations Search
  [OrganizationsSearchBarState.mountPoint]: OrganizationsSearchBarState.reducer,

  // Users Search
  [UsersSearchBarState.mountPoint]: UsersSearchBarState.reducer,

  // PartView
  [PartViewDashboardState.mountPoint]: PartViewDashboardState.reducer,
  [PartViewBackOrderDashboardState.mountPoint]:
    PartViewBackOrderDashboardState.reducer,
  [PartViewSearchBarState.mountPoint]: PartViewSearchBarState.reducer,
  [PartViewSavedSearchState.mountPoint]: PartViewSavedSearchState.reducer,
  [PartViewSavedSearchCardsState.mountPoint]:
    PartViewSavedSearchCardsState.reducer,
  [PartViewEntityDetailsState.mountPoint]: PartViewEntityDetailsState.reducer,
  [PartViewSubscriptionState.mountPoint]: PartViewSubscriptionState.reducer,
  [PartViewWatchedPackagesSubscriptionState.mountPoint]:
    PartViewWatchedPackagesSubscriptionState.reducer,
  [PartViewDeliverPackageState.mountPoint]: PartViewDeliverPackageState.reducer,
  // PartView - Filter loaders
  [partViewOriginOptionsState.mountPoint]: partViewOriginOptionsState.reducer,
  [partViewDestinationOptionsState.mountPoint]:
    partViewDestinationOptionsState.reducer,
  [partViewOriginStateOptionsState.mountPoint]:
    partViewOriginStateOptionsState.reducer,
  [partViewDestinationStateOptionsState.mountPoint]:
    partViewDestinationStateOptionsState.reducer,
  [partViewCarrierOptionsState.mountPoint]: partViewCarrierOptionsState.reducer,
  [partViewPartNumberFilterOptionsState.mountPoint]:
    partViewPartNumberFilterOptionsState.reducer,
  [partViewPackageStatusOptionsState.mountPoint]:
    partViewPackageStatusOptionsState.reducer,
  [partViewActiveExceptionOptionsState.mountPoint]:
    partViewActiveExceptionOptionsState.reducer,
  [partViewOrderPriorityOptionsState.mountPoint]:
    partViewOrderPriorityOptionsState.reducer,
  // PartView - Category Loaders
  [partViewShipmentIdOptionsState.mountPoint]:
    partViewShipmentIdOptionsState.reducer,
  [partViewPartNumberOptionsState.mountPoint]:
    partViewPartNumberOptionsState.reducer,
  [partViewOrderNumberOptionsState.mountPoint]:
    partViewOrderNumberOptionsState.reducer,
  [partViewPackageNumberOptionsState.mountPoint]:
    partViewPackageNumberOptionsState.reducer,
  [partViewBillOfLadingOptionsState.mountPoint]:
    partViewBillOfLadingOptionsState.reducer,

  //Dealer PartView
  [DealerPartViewSearchBarState.mountPoint]:
    DealerPartViewSearchBarState.reducer,
  [DealerPartViewSavedSearchState.mountPoint]:
    DealerPartViewSavedSearchState.reducer,
  [DealerPartViewSavedSearchCardsState.mountPoint]:
    DealerPartViewSavedSearchCardsState.reducer,
  [dealerOriginStateOptionsState.mountPoint]:
    dealerOriginStateOptionsState.reducer,
  [dealerDestinationStateOptionsState.mountPoint]:
    dealerDestinationStateOptionsState.reducer,
  [dealerPartNumberOptionsState.mountPoint]:
    dealerPartNumberOptionsState.reducer,
  [dealerPackageStatusOptionsState.mountPoint]:
    dealerPackageStatusOptionsState.reducer,
  [dealerActiveExceptionOptionsState.mountPoint]:
    dealerActiveExceptionOptionsState.reducer,
  [dealerOrderPriorityOptionsState.mountPoint]:
    dealerOrderPriorityOptionsState.reducer,
  [dealerPVOrgOptionsState.mountPoint]: dealerPVOrgOptionsState.reducer,
  // Shipment Dwell Notification Search
  [ShipmentDwellNotificationSearchBarState.mountPoint]:
    ShipmentDwellNotificationSearchBarState.reducer,
  [shipmentDwellNotificationLocationOptionsState.mountPoint]:
    shipmentDwellNotificationLocationOptionsState.reducer,
  [EditShipmentDwellNotificationState.mountPoint]:
    EditShipmentDwellNotificationState.reducer,

  // Container Tracking
  [ContainerTrackingSavedSearchState.mountPoint]:
    ContainerTrackingSavedSearchState.reducer,
  [ContainerTrackingSavedSearchCardsState.mountPoint]:
    ContainerTrackingSavedSearchCardsState.reducer,
  [ContainerTrackingSearchBarState.mountPoint]:
    ContainerTrackingSearchBarState.reducer,
  [ContainerTrackingRouteManagementSearchState.mountPoint]:
    ContainerTrackingRouteManagementSearchState.reducer,
  [ContainerTrackingManagementSearchState.mountPoint]:
    ContainerTrackingManagementSearchState.reducer,
  [ContainerTrackingDomainDataState.mountPoint]:
    ContainerTrackingDomainDataState.reducer,
  [ContainerTrackingCountsWidgetState.mountPoint]:
    ContainerTrackingCountsWidgetState.reducer,
  [ContainerTrackingDetailsWidgetState.mountPoint]:
    ContainerTrackingDetailsWidgetState.reducer,
  [ContainerTrackingLocationManagementState.mountPoint]:
    ContainerTrackingLocationManagementState.reducer,
  [ctLocationOptionsState.mountPoint]: ctLocationOptionsState.reducer,
  [ctSupplierCodeFilterOptions.mountPoint]: ctSupplierCodeFilterOptions.reducer,
  [ctProgramCodeFilterOptions.mountPoint]: ctProgramCodeFilterOptions.reducer,
  [ctContainerTypeFilterOptions.mountPoint]:
    ctContainerTypeFilterOptions.reducer,
  [ctLocationOnRouteFilterOptions.mountPoint]:
    ctLocationOnRouteFilterOptions.reducer,
  [ctSensorFilterOptions.mountPoint]: ctSensorFilterOptions.reducer,

  [ContainerTrackingMyWatchedContainersState.mountPoint]:
    ContainerTrackingMyWatchedContainersState.reducer,
  [ContainerTrackingContainerTypeTableState.mountPoint]:
    ContainerTrackingContainerTypeTableState.reducer,

  // Surgical Tote Tracking
  [surgicalTrackingUniqueDeviceId.mountPoint]:
    surgicalTrackingUniqueDeviceId.reducer,
  [SurgicalToteTrackingSearchBarState.mountPoint]:
    SurgicalToteTrackingSearchBarState.reducer,
  [SurgicalToteTrackingSavedSearchState.mountPoint]:
    SurgicalToteTrackingSavedSearchState.reducer,
  [SurgicalToteTrackingSavedSearchCardsState.mountPoint]:
    SurgicalToteTrackingSavedSearchCardsState.reducer,
  [SurgicalToteTrackingDomainDataState.mountPoint]:
    SurgicalToteTrackingDomainDataState.reducer,
  [SurgicalToteTrackingCountsWidgetState.mountPoint]:
    SurgicalToteTrackingCountsWidgetState.reducer,
  [SurgicalToteTrackingMyWatchedTotesState.mountPoint]:
    SurgicalToteTrackingMyWatchedTotesState.reducer,
  [SurgicalToteTrackingDetailsWidgetState.mountPoint]:
    SurgicalToteTrackingDetailsWidgetState.reducer,
  [hsttLocationOptionsState.mountPoint]: hsttLocationOptionsState.reducer,

  // Dealer DriveAway
  [DriveAwayDomainDataState.mountPoint]: DriveAwayDomainDataState.reducer,
  [DriveAwayEntitiesState.mountPoint]: DriveAwayEntitiesState.reducer,
  [DriveAwaySearchBarState.mountPoint]: DriveAwaySearchBarState.reducer,
  [DriveAwaySavedSearchState.mountPoint]: DriveAwaySavedSearchState.reducer,
  [DriveAwaySavedSearchCardsState.mountPoint]:
    DriveAwaySavedSearchCardsState.reducer,
  [RequestDriveAwayState.mountPoint]: RequestDriveAwayState.reducer,
  [DriveAwayStatusHistoryState.mountPoint]: DriveAwayStatusHistoryState.reducer,
  [DriveAwayVinHistorySearchBarState.mountPoint]:
    DriveAwayVinHistorySearchBarState.reducer,
  [DriveAwayReviewRequestState.mountPoint]: DriveAwayReviewRequestState.reducer,
  [DriveAwayExportPickUpFormState.mountPoint]:
    DriveAwayExportPickUpFormState.reducer,
  [DriveAwayBulkActionState.mountPoint]: DriveAwayBulkActionState.reducer,

  // Filter loaders
  [driveAwayLocationsOptionsState.mountPoint]:
    driveAwayLocationsOptionsState.reducer,
  [driveAwayCustomersOptionsState.mountPoint]:
    driveAwayCustomersOptionsState.reducer,
  [driveAwayDealersOptionsState.mountPoint]:
    driveAwayDealersOptionsState.reducer,
  [driveAwayProductTypeOptionsState.mountPoint]:
    driveAwayProductTypeOptionsState.reducer,
  [driveAwayDealerRegionOptionsState.mountPoint]:
    driveAwayDealerRegionOptionsState.reducer,
  [driveAwayDealerZoneOptionsState.mountPoint]:
    driveAwayDealerZoneOptionsState.reducer,
  [driveAwayDealerDistrictOptionsState.mountPoint]:
    driveAwayDealerDistrictOptionsState.reducer,
  [driveAwayDestinationOptionsState.mountPoint]:
    driveAwayDestinationOptionsState.reducer,

  // Internal Tools
  [VinEtaValidatorSearchBarState.mountPoint]:
    VinEtaValidatorSearchBarState.reducer,
  [vinEtaValidatorVinOptionsState.mountPoint]:
    vinEtaValidatorVinOptionsState.reducer,
  [ShipmentEtaValidatorSearchBarState.mountPoint]:
    ShipmentEtaValidatorSearchBarState.reducer,
  [FreezeETAState.mountPoint]: FreezeETAState.reducer,
  [DPUAdminToolState.mountPoint]: DPUAdminToolState.reducer,
  [DebugEtaToolState.mountPoint]: DebugEtaToolState.reducer,
  [FreezePartViewETAState.mountPoint]: FreezePartViewETAState.reducer,
  [SetManualETARangeToolState.mountPoint]: SetManualETARangeToolState.reducer,
  [PartViewDealerOnboardingToolState.mountPoint]:
    PartViewDealerOnboardingToolState.reducer,
  [DPUVinHistorySearchBarState.mountPoint]: DPUVinHistorySearchBarState.reducer,
  [PartViewDeliveryStatusUpdateState.mountPoint]:
    PartViewDeliveryStatusUpdateState.reducer,

  // Notification Management
  [NotificationManagementState.mountPoint]: NotificationManagementState.reducer,
  [NotificationManagementWizardState.mountPoint]:
    NotificationManagementWizardState.reducer,

  // Administrative Tools
  [ShipmentReportArrivalDepartureState.mountPoint]:
    ShipmentReportArrivalDepartureState.reducer,
};

export default function configureStore(preloadedState) {
  const { reducer, middleware, enhancer } = connectRoutes(routesMap, {
    createHistory: createBrowserHistory,
    querySerializer: qs,
  });

  let appMiddlewares = [thunkMiddleware];
  if (ApplicationConfig.ENABLE_REDUX_LOGGER) {
    appMiddlewares.push(createLogger());
  }

  const appliedMiddlewares = applyMiddleware(middleware, ...appMiddlewares);
  const enhancers = composeWithDevTools(enhancer, appliedMiddlewares);
  const reducers = combineReducers({
    [ROUTER_LOCATION_KEY]: reducer,
    ...appReducers,
  });
  const store = createStore(reducers, preloadedState, enhancers);
  return {
    store,
  };
}
