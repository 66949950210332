/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Text, FontSize } from "components/atoms/Text.atom";
import Colors from "styles/colors";
import { useTranslation } from "react-i18next";
import { translateCurrentPositionType } from "shared/utils/entity.utils";
import { getDaysHrsMinsBetweenTimestamps } from "utils/date-time";
import { Fragment } from "react";
import { LocationAddressComplex } from "components/molecules/LocationAddressBlock.molecule";
import moment from "moment";
import { DateTime } from "components/atoms/DateTime.atom";
import { ShippabilityStatusText } from "shared/components/molecules/ShippabilityStatusText.molecule";

const calculateColorBasedOnDuration = (calculatedDuration) => {
  let diffDuration = moment.duration(calculatedDuration.duration).asDays();

  if (diffDuration < 0) {
    return Colors.text.GRAY;
  }

  switch (true) {
    case diffDuration >= 10:
      return Colors.inventoryView.RED;
    case diffDuration >= 6 && diffDuration < 10:
      return Colors.inventoryView.ORANGE;
    case diffDuration >= 2 && diffDuration < 6:
      return Colors.inventoryView.ORANGE_YELLOW;
    case diffDuration >= 0 && diffDuration < 2:
      return Colors.inventoryView.GREEN;
    default:
      return Colors.text.GRAY;
  }
};

const VinCell = (props) => {
  const { t } = useTranslation("inventory-view");
  const {
    entityExternalId,
    productType,
    isShippable,
    orderType,
    estimatedProductionTs,
    initialForecastedUltimateDestinationArrivalTs,
  } = props.value;
  return (
    <div>
      <Text bold block size={FontSize.size14}>
        {entityExternalId}
      </Text>
      <Text block size={FontSize.size14}>
        {productType}
      </Text>
      <Text bold size={FontSize.size14}>
        {t("inventory-view:Order Type")}:{" "}
      </Text>
      {orderType ? (
        <Text>{orderType}</Text>
      ) : (
        <Text size={FontSize.size12}>{t("inventory-view:N/A")}</Text>
      )}

      <br />
      <Text bold size={FontSize.size14}>
        {t("inventory-view:Production Date")}:{" "}
      </Text>
      <DateTime
        localize
        showDate
        dateTime={estimatedProductionTs}
        showTime={false}
        fallback={<Text size={FontSize.size12}>{t("inventory-view:N/A")}</Text>}
      />
      <br />
      <Text bold size={FontSize.size14}>
        {t("inventory-view:Gate Release")}:{" "}
      </Text>
      <DateTime
        localize
        showDate
        dateTime={initialForecastedUltimateDestinationArrivalTs}
        showTime={false}
        fallback={<Text size={FontSize.size12}>{t("inventory-view:N/A")}</Text>}
      />
      <ShippabilityStatusText
        isShippable={isShippable}
      ></ShippabilityStatusText>
    </div>
  );
};

VinCell.propTypes = {
  value: PropTypes.shape({
    entityExternalId: PropTypes.string,
    productType: PropTypes.string,
    isShippable: PropTypes.bool,
    orderType: PropTypes.string,
    estimatedProductionTs: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    initialForecastedUltimateDestinationArrivalTs: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
};

const CurrentLocationCell = (props) => {
  const { positionType, locationName } = props.value;
  return (
    <div>
      <Text block>{positionType}</Text>
      <Text block bold>
        {locationName ?? ""}
      </Text>
    </div>
  );
};

CurrentLocationCell.propTypes = {
  value: PropTypes.shape({
    positionType: PropTypes.string,
    locationName: PropTypes.string,
  }),
};

const ForecastedArrivalCell = (props) => {
  const { t } = useTranslation("inventory-view");
  return (
    <div>
      <Text bold block size={FontSize.size14}>
        {t("inventory-view:Forecasted Arrival")}
      </Text>
      <DateTime
        localize
        showDate
        dateTime={props.value}
        showTime={false}
        fallback={<Text size={FontSize.size12}>{t("inventory-view:N/A")}</Text>}
      />
    </div>
  );
};

ForecastedArrivalCell.propTypes = {
  value: PropTypes.string,
};

const InitialEtaCell = (props) => {
  const { t } = useTranslation("inventory-view");
  return (
    <DateTime
      localize
      showDate
      dateTime={props.value}
      showTime={false}
      fallback={<Text size={FontSize.size12}>{t("inventory-view:N/A")}</Text>}
    />
  );
};

InitialEtaCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const TimeOnSiteCell = (props) => {
  const { t } = useTranslation("inventory-view");
  const { arrivalTs, departureTs } = props.value;

  let calculatedDuration = getDaysHrsMinsBetweenTimestamps(moment(), arrivalTs);

  const setColor = calculateColorBasedOnDuration(calculatedDuration);

  const displayDuration =
    moment.duration(calculatedDuration.duration).minutes() > 0;

  const formattedDurationText = displayDuration ? (
    <Text block bold css={{ color: setColor }}>
      {calculatedDuration?.formattedDuration}
    </Text>
  ) : null;

  return (
    <div>
      {arrivalTs && !departureTs ? (
        <Fragment>
          <Text bold size={FontSize.size14}>
            {t("inventory-view:Arrival")}:{" "}
          </Text>
          <DateTime
            localize
            showDate
            dateTime={arrivalTs}
            showTime={false}
            fallback={
              <Text size={FontSize.size12}>{t("inventory-view:N/A")}</Text>
            }
          />
          <Text block>{formattedDurationText}</Text>
        </Fragment>
      ) : departureTs ? (
        <Fragment>
          <Text bold block>
            {t("inventory-view:Departed")}
          </Text>
        </Fragment>
      ) : (
        <Text bold block>
          {t("inventory-view:Not yet Arrived")}
        </Text>
      )}
    </div>
  );
};

TimeOnSiteCell.propTypes = {
  value: PropTypes.shape({
    arrivalTs: PropTypes.string,
    departureTs: PropTypes.string,
  }),
};

const DestinationCell = (props) => {
  const { destination } = props.value;
  return (
    <div>
      <Text size={FontSize.size14} bold block>
        {destination?.code ?? ""}
      </Text>
      <Text size={FontSize.size14} block>
        {destination?.name ?? ""}
      </Text>
      <LocationAddressComplex
        address1={destination?.address ?? ""}
        city={destination?.city ?? ""}
        state={destination?.state ?? ""}
        country={destination?.country ?? ""}
        defaultTextColor={Colors.text.DARK_GRAY}
        addressColor={Colors.text.DARK_GRAY}
      />
    </div>
  );
};

DestinationCell.propTypes = {
  value: PropTypes.shape({
    destination: PropTypes.shape({
      code: PropTypes.string,
      state: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
    }),
  }),
};

export const useColumns = () => {
  const { t } = useTranslation("inventory-view");

  const columns = [
    {
      Header: t("inventory-view:VIN"),
      accessor: (obj) => {
        return {
          entityExternalId: obj?.entityExternalId ?? "",
          productType: obj?.productType,
          isShippable: obj?.shippableAtTs ?? false,
          orderType: obj?.orderType ?? "",
          estimatedProductionTs: obj?.estimatedProductionTs ?? "",
          initialForecastedUltimateDestinationArrivalTs:
            obj?.initialForecastedUltimateDestinationArrivalTs ?? "",
        };
      },
      width: 200,
      Cell: VinCell,
    },
    {
      Header: t("inventory-view:Current Location"),
      accessor: (obj) => {
        const currentPosition = obj?.currentPosition ?? {};
        return {
          locationName: currentPosition?.location?.name ?? "",
          positionType:
            translateCurrentPositionType(t, currentPosition?.type) ?? "",
        };
      },
      width: 150,
      Cell: CurrentLocationCell,
    },
    {
      Header: t("inventory-view:Forecasts"),
      accessor: (obj) => {
        return obj.forecastedArrivalTs ?? "";
      },
      width: 150,
      Cell: ForecastedArrivalCell,
    },
    {
      Header: t("inventory-view:Initial ETA"),
      accessor: (obj) => {
        return obj.initialForecastedUltimateDestinationArrivalTs;
      },
      width: 150,
      Cell: InitialEtaCell,
    },
    {
      Header: t("inventory-view:Destination Details"),
      accessor: (obj) => {
        return {
          destination: obj.ultimateDestinationLocation ?? {},
        };
      },
      width: 150,
      Cell: DestinationCell,
    },
    {
      Header: t("inventory-view:Time on Site"),
      accessor: (obj) => {
        return {
          arrivalTs: obj.arrivalTs,
          departureTs: obj.departureTs,
        };
      },
      width: 150,
      Cell: TimeOnSiteCell,
    },
  ];

  return columns;
};
