import _ from "lodash";
import moment from "moment";
import Colors from "styles/colors";
import {
  getDifferenceBetweenTimestamps,
  stringToMoment,
} from "utils/date-time";
import { dataTypes } from "../insights/components/InventoryCharts.Columns";

// Victory Chart style object
export const barStyle = {
  data: {
    cursor: "pointer",
    width: 30,
    strokeWidth: 0,
  },
  labels: { fontSize: 12 },
};

export const getStyle = (bar) => {
  return {
    ...barStyle,
    labels: {
      fill: bar.textColor,
      fontSize: 12,
    },
    data: {
      fill: ({ datum }) => {
        if (
          datum.isTodaysDate &&
          (bar.y === dataTypes.SHIPPABLE || bar.y === dataTypes.REPORTED)
        ) {
          return Colors.graphs.CHART_CYAN_BLUE;
        }
        return bar.color;
      },
      cursor: ({ datum }) => {
        return datum.isClickable ? "pointer" : "auto";
      },
    },
  };
};

export const getLabels = (bar) => {
  return ({ datum }) => {
    if (datum[bar.y] > 0) {
      if (
        bar.y === dataTypes.NONSHIPPABLE &&
        datum.shippable !== 0 &&
        datum.nonShippable !== 0
      ) {
        return `${datum.summed}\n \n${datum.nonShippable}`;
      } else if (
        bar.y === dataTypes.FORECASTED &&
        datum.reported !== 0 &&
        datum.forecasted !== 0
      ) {
        return `${datum.summed}\n \n${datum.forecasted}`;
      }
      return datum[bar.y];
    }
    return null;
  };
};

/**
 * Formats the X-axis name based on the provided datum.
 *
 * This function checks if the datum object has a property called `formattedDate`.
 * If it exists, it returns the `formattedDate` value. Otherwise, it returns the `name` property of the datum.
 *
 * @param {object} datum - The data object containing the name or formatted date.
 * @param {string} datum.name - The original name of the data point. (optional)
 * @param {string} datum.formattedDate - A pre-formatted date string for the X-axis. (optional)
 * @returns {string} The formatted name to be used for the X-axis.
 */
export const formatXAxisName = (datum) => {
  return datum.formattedDate ? datum.formattedDate : datum.name;
};

export const calculateLabelPosition = (bar) => {
  return ({ datum }) => {
    return datum[bar.y] < 160 ? -20 : 20;
  };
};
export const generateTickFormat = ({ difference, maxLimit }) => {
  let i = 0;
  const tickFormat = [i];
  while (i < maxLimit) {
    const newValue = i + difference;
    tickFormat.push(newValue);
    i = newValue;
  }
  return tickFormat;
};

const inventoryColorCodes = {
  85: Colors.inventoryView.ORANGE_YELLOW,
  100: Colors.inventoryView.RED,
  default: Colors.inventoryView.GREEN,
};

const daysOnSiteColorCodes = {
  5: Colors.inventoryView.RED,
  default: Colors.inventoryView.BLUE,
};

const forecastedArrivalsColorCodes = {
  default: Colors.inventoryView.BLUE,
};

const departureColorCodes = {
  85: Colors.inventoryView.ORANGE_YELLOW,
  100: Colors.inventoryView.GREEN,
  default: Colors.inventoryView.RED,
};

const getColorTheme = (insightBlock) => {
  switch (insightBlock) {
    case "inventory":
      return inventoryColorCodes;
    case "daysOnSite":
      return daysOnSiteColorCodes;
    case "forecastedArrivals":
      return forecastedArrivalsColorCodes;
    case "departures":
      return departureColorCodes;
    default:
      return null;
  }
};

export const getColors = (insightBlock, value, capacity = 0) => {
  const theme = getColorTheme(insightBlock);
  let percentage = 0;
  if (capacity) {
    percentage = Math.trunc((value / capacity) * 100);
  }

  for (const threshold of Object.keys(theme).sort()) {
    if (percentage >= threshold) {
      return theme[threshold];
    }
  }

  // Default case
  return theme.default;
};

const formatDateAndCheckTodaysDate = (date) => {
  const now = moment().format("YYYY-MM-DD");
  const monthYear = stringToMoment(date);
  const timeDifferenceInDays = parseInt(
    getDifferenceBetweenTimestamps(now, monthYear),
  );
  const isTodaysDate = timeDifferenceInDays === 0 ? true : false;
  const formattedDate = monthYear.isValid()
    ? moment(monthYear).format("MM/DD")
    : false;
  return { isTodaysDate, formattedDate };
};

export const transformInventoryChartData = (data, locationId) => {
  const { inventoryData, forecastedData } = data;

  return {
    data: Object.keys(inventoryData)
      .sort((a, b) => new Date(a) - new Date(b)) // Sort dates in descending order
      .map((date) => {
        const { isTodaysDate, formattedDate } =
          formatDateAndCheckTodaysDate(date);
        const transformedData = {
          locationId,
          name: date,
          shippable: inventoryData[date].shippable,
          nonShippable: inventoryData[date].nonShippable,
          forecasted: forecastedData[date] ?? 0,
          reported: 0,
          today: 0,
          capacity: 0,
          isTodaysDate,
          formattedDate,
          isClickable: isTodaysDate ? true : false,
        };

        return transformedData;
      }),
  };
};

export const transformPipelineChartData = (data, locationId) => {
  let { shippable, nonShippable } = data;
  const locationTypes = [
    ...Object.keys(shippable),
    ...Object.keys(nonShippable),
  ];

  const uniqueLocationTypes = _.uniqWith(locationTypes, _.isEqual);

  // TODO: API change to remove total from response
  const filteredTypes = uniqueLocationTypes.filter(function (item) {
    return item !== "total";
  });

  const formattedData = filteredTypes.map((location) => ({
    locationId: locationId,
    name: location,
    shippable: shippable[location] ?? 0,
    nonShippable: nonShippable[location] ?? 0,
    isClickable: true,
  }));

  return formattedData;
};

export const transformArrivalGraphData = (data, locationId) => {
  const { arrivalData, forecastedData } = data;

  const allDates = Object.keys(arrivalData).concat(Object.keys(forecastedData));

  return _.chain(allDates)
    .uniq()
    .sortBy((date) => new Date(date))
    .map((date) => {
      const { isTodaysDate, formattedDate } =
        formatDateAndCheckTodaysDate(date);

      return {
        locationId: locationId,
        name: date,
        today: 0,
        reported: arrivalData[date] ?? 0, // Default to 0 if no value in past
        forecasted: forecastedData[date] ?? 0, // Default to 0 if no value in future
        isTodaysDate,
        formattedDate,
        isClickable: true,
      };
    })
    .value();
};

export const transformDepartureGraphData = (data) => {
  const { departureData, locationDetailsData } = data;

  const yAxisDates = Object.keys(departureData);
  const sum = Object.values(departureData).reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  );

  const yAxisDatesCalculatedAverage = Math.round(sum / 7);

  let transformedDatesData = yAxisDates.map((item) => {
    const { formattedDate } = formatDateAndCheckTodaysDate(item);
    const isZeroValue = departureData[item] === 0 ?? false;
    return {
      x: item,
      y: isZeroValue ? null : departureData[item],
      projectedTrend: isZeroValue ? yAxisDatesCalculatedAverage : null,
      formattedDate: formattedDate,
    };
  });

  // TODO: update to camelcase in API response
  transformedDatesData = {
    data: transformedDatesData,
    capacity: locationDetailsData.capacity,
    primaryDepartureTarget: locationDetailsData.primary_departure_target,
    secondaryDepartureTarget: locationDetailsData.secondary_departure_target,
    projectedTrend: yAxisDatesCalculatedAverage,
  };

  return transformedDatesData;
};
