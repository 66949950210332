import _ from "lodash";

import { customerApiUrl } from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import { combineReducers } from "redux";

const STORE_MOUNT_POINT = "inventoryViewDomainData";

const inventoryViewCurrentLocationTypesDuck = buildFetchDuck(
  "inventoryViewCurrentLocaitonTypesDuck",
);

export function fetchDomainData(inventoryLocationId) {
  const urlCurrentLocationType = customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search/options/current-location-type`,
  );

  return (dispatch) => {
    dispatch(
      inventoryViewCurrentLocationTypesDuck.fetch(urlCurrentLocationType),
    );
  };
}

const createSelectorFromProperty = (property, parent, sort = false) => {
  return (state) => {
    let dataFromState = state[STORE_MOUNT_POINT][parent]?.data[property];

    if (dataFromState) {
      if (sort) {
        // Sorting
        if (_.isString(sort)) {
          return _.sortBy(dataFromState, sort);
        }

        // Check if the data is prone to sort. In this case, the only possible
        // type which comes from the backend and is not sortable is object
        if (_.isObject(dataFromState[0])) {
          throw Error(
            `Property ${property} contains objects that can not be sorted`,
          );
        }

        return dataFromState.sort();
      }
      return dataFromState;
    }
    return [];
  };
};

const getCurrentLocationTypes = createSelectorFromProperty(
  "currentLocationTypes",
  "currentLocationTypes",
);

const InventoryViewDomainData = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getCurrentPositionTypes: getCurrentLocationTypes,
  },
  reducer: combineReducers({
    currentLocationTypes: inventoryViewCurrentLocationTypesDuck.reducer,
  }),
};

export default InventoryViewDomainData;
