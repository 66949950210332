import axios from "axios";
import { z } from "zod";

import apiUrl, { customerApiUrl } from "api-url";
import { BaseErrorResponse } from "api/responses";
import { createQueryRequestHook } from "shared/hooks/createQueryRequestHook";
import { transformDepartureGraphData } from "../utils/chart.utils";
import {
  transformInventoryChartData,
  transformPipelineChartData,
  transformArrivalGraphData,
} from "../utils/chart.utils";
import { useInventoryTranslation } from "shared/hooks/useInventoryTranslation";

//For Inventory Chart
const inventoryChartResponseSchema = z.record(
  z.record(
    z.string(),
    z.object({
      shippable: z.number().int().min(0),
      nonShippable: z.number().int().min(0),
    }),
  ),
  z.record(
    z.string(),
    z.object({
      shippable: z.number().int().min(0),
      nonShippable: z.number().int().min(0),
    }),
  ),
);

type InventoryGraphResponse = z.infer<typeof inventoryChartResponseSchema>;

const inventoryChartParamsSchema = z.object({
  locationId: z.string(),
  startDate: z.string(),
  endDate: z.string(),
});

type InventoryChartParams = z.infer<typeof inventoryChartParamsSchema>;

const useInventoryGraphQuery = (params: InventoryChartParams) => {
  return createQueryRequestHook<InventoryGraphResponse, BaseErrorResponse>({
    queryKey: [
      "inventoryGraph",
      params.locationId,
      params.startDate,
      params.endDate,
    ],
    queryFn: async () => {
      const urls = [
        axios.get(
          customerApiUrl(
            `/entity-inventory/location/${params.locationId}/metrics/inventory`,
          ),
          { params: { startDate: params.startDate, endDate: params.endDate } },
        ),
        axios.get(
          customerApiUrl(
            `/entity-inventory/location/${params.locationId}/metrics/forecasted-arrival`,
          ),
        ),
      ];
      const [inventoryResponse, forecastedResponse] = await axios.all(urls);

      return {
        inventoryData: inventoryResponse.data,
        forecastedData: forecastedResponse.data,
      };
    },
    initialData: { inventoryData: {}, forecastedData: {} },
    refetchOnWindowFocus: false,
    retry: false,
  })();
};

export const useInventoryGraph = (params: InventoryChartParams) => {
  const { data, isFetching, isError } = useInventoryGraphQuery(params);
  const transformedData = transformInventoryChartData(data, params.locationId);

  return { transformedData, isFetching, isError };
};

//For Pipeline Chart
const pipelineChartResponseSchema = z.object({
  total: z.number(),
  shippable: z.object({
    OntheRoad: z.number(),
    OnRail: z.number(),
    OntheWater: z.number(),
    Plant: z.number(),
    Distribution: z.number(),
    Upfitter: z.number(),
    Port: z.number(),
  }),
  nonShippable: z.object({
    OntheRoad: z.number(),
    OnRail: z.number(),
    OntheWater: z.number(),
    Plant: z.number(),
    Distribution: z.number(),
    Upfitter: z.number(),
    Port: z.number(),
  }),
});

type PipelineGraphResponse = z.infer<typeof pipelineChartResponseSchema>;
const pipelineChartSchema = z.object({
  locationId: z.string(),
});

type PipelineChartParams = z.infer<typeof pipelineChartSchema>;

let initialData = {
  total: 0,
  shippable: {
    OntheRoad: 0,
    OnRail: 0,
    OntheWater: 0,
    Plant: 0,
    Distribution: 0,
    Upfitter: 0,
    Port: 0,
  },
  nonShippable: {
    OntheRoad: 0,
    OnRail: 0,
    OntheWater: 0,
    Plant: 0,
    Distribution: 0,
    Upfitter: 0,
    Port: 0,
  },
};

const usePipelineGraphQuery = (params: PipelineChartParams) => {
  let { locationId } = params;
  return createQueryRequestHook<PipelineGraphResponse, BaseErrorResponse>({
    queryKey: ["pipelineGraph", locationId],
    queryFn: async () => {
      const url = customerApiUrl(
        `/entity-inventory/location/${locationId}/metrics/pipeline`,
      );
      const response = await axios.get(url);
      return response.data;
    },
    initialData: initialData,
    refetchOnWindowFocus: false,
    retry: false,
  })();
};

export const usePipelineGraph = (params: PipelineChartParams) => {
  const { getTranslatedLabelsPipelineGraph } = useInventoryTranslation();

  const { data, isFetching, isError } = usePipelineGraphQuery(params);
  let transformedData = transformPipelineChartData(data, params.locationId);

  let translatedData = transformedData.map((item: any) => {
    return {
      ...item,
      name: getTranslatedLabelsPipelineGraph(item.name),
    };
  });

  return { transformedData: translatedData, isFetching, isError };
};

//For Arrival Graph
const arrivalGraphResponseSchema = z.record(
  z.record(z.string(), z.number()),
  z.record(z.string(), z.number()),
);

type ArrivalGraphResponse = z.infer<typeof arrivalGraphResponseSchema>;

const arrivalGraphParamsSchema = z.object({
  locationId: z.string(),
  arrivalDateInUTC: z.string(),
  todaysDateInUTC: z.string(),
  forecastedEndDateInUTC: z.string(),
});

type ArrivalGraphParams = z.infer<typeof arrivalGraphParamsSchema>;

const useArrivalGraphQuery = (params: ArrivalGraphParams) => {
  const {
    locationId,
    arrivalDateInUTC,
    todaysDateInUTC,
    forecastedEndDateInUTC,
  } = params;

  return createQueryRequestHook<ArrivalGraphResponse, BaseErrorResponse>({
    queryKey: [
      "arrivalGraph",
      locationId,
      arrivalDateInUTC,
      todaysDateInUTC,
      forecastedEndDateInUTC,
    ],
    queryFn: async () => {
      const urls = [
        axios.get(
          customerApiUrl(
            `/entity-inventory/location/${locationId}/metrics/actual-arrival`,
          ),
          { params: { startDate: arrivalDateInUTC, endDate: todaysDateInUTC } },
        ),
        axios.get(
          customerApiUrl(
            `/entity-inventory/location/${locationId}/metrics/forecasted-arrival`,
          ),
        ),
      ];
      const [arrivalResponse, forecastedResponse] = await axios.all(urls);
      return {
        arrivalData: arrivalResponse.data,
        forecastedData: forecastedResponse.data,
      };
    },
    initialData: { arrivalData: {}, forecastedData: {} },
    refetchOnWindowFocus: false,
    retry: false,
  })();
};

export const useArrivalGraph = (params: ArrivalGraphParams) => {
  const { data, isFetching, isError } = useArrivalGraphQuery(params);
  const transformedData = transformArrivalGraphData(data, params.locationId);

  return { transformedData, isFetching, isError };
};

const departureGraphResponseSchema = z.object({
  departureData: z.object({}),
  locationDetailsData: z.object({
    capacity: z.number().int().min(0),
    primaryDepartureTarget: z.number().int().min(0),
    secondaryDepartureTarget: z.number().int().min(0),
  }),
});

type DepartureGraphResponse = z.infer<typeof departureGraphResponseSchema>;

const departureGraphParamsSchema = z.object({
  locationId: z.string(),
  startDate: z.string(),
  endDate: z.string(),
});

type DepartureChartParams = z.infer<typeof departureGraphParamsSchema>;

const useDepartureGraphQuery = (params: DepartureChartParams) => {
  return createQueryRequestHook<DepartureGraphResponse, BaseErrorResponse>({
    queryKey: [
      "departureGraph",
      params.locationId,
      params.startDate,
      params.endDate,
    ],
    queryFn: async () => {
      const urls = [
        axios.get(
          customerApiUrl(
            `/entity-inventory/location/${params.locationId}/metrics/actual-departure`,
          ),
          { params: { startDate: params.startDate, endDate: params.endDate } },
        ),
        axios.get(
          apiUrl(`/location/locations/${params.locationId}/feature/INV`),
        ),
      ];
      const [departureResponse, locationDetailsResponse] =
        await axios.all(urls);

      return {
        departureData: departureResponse.data,
        locationDetailsData: locationDetailsResponse.data,
      };
    },
    initialData: {
      departureData: {},
      locationDetailsData: {
        capacity: 0,
        primaryDepartureTarget: 0,
        secondaryDepartureTarget: 0,
      },
    },
    refetchOnWindowFocus: false,
    retry: false,
  })();
};

export const useDepartureGraph = (params: DepartureChartParams) => {
  const { data, isFetching, isError, isLoading } =
    useDepartureGraphQuery(params);
  const transformedData = transformDepartureGraphData(data);

  return { transformedData: transformedData, isFetching, isError, isLoading };
};
